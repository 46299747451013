@mixin breakpoint($size) {
  @if $size == xs {
    @media (max-width: 600px) {
      @content;
    }
  } @else if $size == s {
    @media (min-width: 601px) and (max-width: 768px) {
      @content;
    }
  } @else if $size == m {
    @media (min-width: 769px) and (max-width: 1024px) {
      @content;
    }
  } @else if $size == l {
    @media (min-width: 1025px) and (max-width: 1367px) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: 1368px) and (max-width: 1919px) {
      @content;
    }
  } @else if $size == xxl {
    @media (min-width: 1920px) {
      @content;
    }
  }
}
$width-desktop-page: 85%;

$light: 300;
$regular: 400;
$medium: 500;
$font: "Rubik", sans-serif;

$f-xxsmall: 10px;
$f-xsmall: 11px;
$f-small: 10px;
$f-regular: 12px;
$f-medium: 14px;
$f-xmedium: 16px;
$f-xbig: 17px;
$f-big: 18px;
$f-subtitle: 20px;
$f-title: 22px;
$rounded: 28px;

$primary-color: #f76902;
$secondary-color: #ce143d;
$thirty-color: #e64c0f;

$suvinil-primary: (
  50: lighten($primary-color, 50%),
  100: lighten($primary-color, 40%),
  200: lighten($primary-color, 30%),
  300: lighten($primary-color, 20%),
  400: lighten($primary-color, 10%),
  500: $primary-color,
  600: darken($primary-color, 10%),
  700: darken($primary-color, 20%),
  800: darken($primary-color, 30%),
  900: darken($primary-color, 40%),
  A100: white,
  A200: white,
  A400: lighten($primary-color, 40%),
  A700: lighten($primary-color, 30%),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$suvinil-accent: (
  50: lighten($secondary-color, 50%),
  100: lighten($secondary-color, 40%),
  200: lighten($secondary-color, 30%),
  300: lighten($secondary-color, 20%),
  400: lighten($secondary-color, 10%),
  500: $secondary-color,
  600: darken($secondary-color, 10%),
  700: darken($secondary-color, 20%),
  800: darken($secondary-color, 30%),
  900: darken($secondary-color, 40%),
  A100: white,
  A200: white,
  A400: lighten($secondary-color, 40%),
  A700: lighten($secondary-color, 30%),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$suvinil-alternative: (
  50: #f3fcfb,
  100: #e1f8f5,
  200: #cdf3ef,
  300: #b9eee9,
  400: #aaebe4,
  500: #9be7df,
  600: #93e4db,
  700: #89e0d7,
  800: #7fddd2,
  900: #6dd7ca,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e8fffc,
  A700: #cffff9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$suvinil-warn: (
  50: #fcf2e7,
  100: #f8dec3,
  200: #f3c89c,
  300: #eeb274,
  400: #eaa256,
  500: #e69138,
  600: #e38932,
  700: #df7e2b,
  800: #db7424,
  900: #d56217,
  A100: #ffffff,
  A200: #ffe3d4,
  A400: #ffc3a1,
  A700: #ffb287,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$suvinil-error: (
  50: #f9e0e0,
  100: #f0b3b3,
  200: #e68080,
  300: #db4d4d,
  400: #d42626,
  500: #cc0000,
  600: #c70000,
  700: #c00000,
  800: #b90000,
  900: #ad0000,
  A100: #ffd7d7,
  A200: #ffa4a4,
  A400: #ff7171,
  A700: #ff5858,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
