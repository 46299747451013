/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "variables";

@include mat.elevation-classes();
@include mat.app-background();

//
$fligoo-primary: mat.m2-define-palette($suvinil-primary);
$fligoo-accent: mat.m2-define-palette($suvinil-accent);
$fligoo-warn: mat.m2-define-palette($suvinil-warn);
$fligoo-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $fligoo-primary,
      accent: $fligoo-accent,
      warn: $fligoo-warn,
    ),
  )
);

@include mat.all-component-themes($fligoo-theme);

html,
app-root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  margin: 1%;
}

/* ----- scrollbar ------*/
::-webkit-scrollbar {
  width: 4px;
  height: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
}


.mat-mdc-snack-bar-handset, .mat-mdc-snack-bar-container, .mat-mdc-snack-bar-label {
  flex: 0 0 auto !important;
}

.snackbar-right-width {
  max-width: 80px !important;
  max-height: 80px !important;
}

.snackbar-full-width {
  width: 350px !important;
  max-height: 80px !important;
}

cdk-virtual-scroll-viewport {
  min-height: auto !important;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 65vh;
  width: 100%;
  display: block;
  font-size: $f-medium;
  font-weight: $medium;
  border-radius: 4px;

  .cdk-virtual-scroll-content-wrapper {
    position: initial !important;
  }

  .cdk-virtual-scroll-spacer {
    height: 0px !important;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 28px;
  }
}
